var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 ml-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Solicitudes de compra")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green"},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"name":"name","label":"Buscar","id":"id","filled":"","dense":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.solicitudes,"search":_vm.search,"mobile-breakpoint":100,"dense":"","item-key":"idsolicitudes","show-select":""},scopedSlots:_vm._u([{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 1)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("Incompleta")]):(item.estatus == 0)?_c('v-chip',{attrs:{"small":"","color":"error","dark":""}},[_vm._v("Pendiente")]):_c('v-chip',{attrs:{"small":"","color":"success","dark":""}},[_vm._v("Completa")])]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":isSelected,"hide-details":"","dense":""},on:{"change":function($event){return select($event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","x-small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}]),model:{value:(_vm.imprimirSolicitudes),callback:function ($$v) {_vm.imprimirSolicitudes=$$v},expression:"imprimirSolicitudes"}})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.formTitle))]),_c('br'),_c('v-spacer'),_c('span',{staticClass:"text-subtitle-1"},[_c('strong',[_vm._v("ID: "+_vm._s(_vm.editedItem.idsolicitudes))])])],1),_c('v-card-text',[_c('span',{staticClass:"text-body-1 primary--text pb-1"},[_vm._v(_vm._s(_vm.editedItem.plantel))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersCompra,"items":_vm.editedItem.articulos,"search":_vm.search,"dense":"","items-per-page":5},scopedSlots:_vm._u([{key:"item.foto",fn:function(ref){
var item = ref.item;
return [(item.foto)?_c('v-img',{staticClass:"white",attrs:{"src":_vm.url + item.foto,"height":"60px","width":"60px","contain":""}}):_vm._e()]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 1)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v("Completa")]):_c('v-chip',{attrs:{"small":"","color":"error","dark":""}},[_vm._v("Pendiente")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"elevation-6",attrs:{"color":"blue darken-1","dark":""},on:{"click":_vm.save}},[_vm._v(" Aceptar solicitud ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"elevation-0",staticStyle:{"display":"table"},attrs:{"height":"100%"}},[_c('div',{staticClass:"text-subtitle-1",staticStyle:{"display":"table-cell","vertical-align":"middle"}},[_c('strong',[_vm._v("Eliminar")]),_vm._v(" ¿Estás seguro que deseas eliminar la solicitud? "),_c('v-card-actions',{staticClass:"pt-6"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v("No, cancelar")]),_c('v-btn',{staticClass:"elevation-6",attrs:{"color":"#5C5C5C","dark":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("Si")])],1)],1)])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-img',{attrs:{"src":require("@/assets/borrar.png")}})],1)],1)],1)],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }